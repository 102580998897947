.item-container { 
  overflow:hidden;
  width:100%;
  float:left;
  position: relative;
}

.item {
  width:100%;
  display:block;
  height:auto;
  overflow:hidden;
  background-size:cover;
  object-fit:fill;
}

.heart {
  position: absolute;
  bottom: 10px;
  right: 15px;
}

.count {
  position: absolute;
  bottom: 15px;
  right:25px;
  font-weight:bold;
  font-size:1.2em;
  color:#fff;
}

.item video {
  object-fit: cover;
}

@media (min-width: 576px) {
  .item-container {
    width:50%;
    height:330px;
  }
}

@media (min-width: 768px) {
  .item-container {
    width:33.333%;
    height:270px;
  }
}

@media (min-width: 992px) {
  .item-container {
    width:25%;
    height:250px;
  }
}

@media (min-width: 1200px) {
}
